import React from 'react';
import './products.css'
import Layout from '../components/layout';
import Link from 'gatsby-link';
import Seo from '../components/SEO';
import { StaticImage } from 'gatsby-plugin-image';
const Bimg = '../images/static-imgs/raspberry-main.webp'
const Mimg = '../images/static-imgs/mango-main.webp'
const Pimg = '../images/static-imgs/pineapple-main.webp'


export default function Products({location}){

    return(

        <Layout location={location}>
        
            <Seo title={`Shop Electrolyte Powders| Bender`} description = 'Electrolyte powder formulated to prevent hangovers'  />

            <div className = 'products-main'>
                {products.map(product => {
                    return(
                        <Link to = '/electrolyte-powder-drink-that-prevents-hangovers' className = 'product-container' state = {{id:product.id}}>
                            <div className = 'product-br-div'>
                                <div className = 'product-img'>
                                    {product.image}
                                </div>
                                <div className = 'product-text'>
                                    <div className = 'product-title'>{product.name}</div>
                                    <div className = 'product-price'>$24.99</div>
                                </div>
                            </div>
                        </Link>
                    )
                })}

            </div>
        </Layout>

    )

}


const products = [
    {
        name: 'Blasted Blue Raspberry',
        image:<StaticImage src = {Bimg}/>,
        id:'1404fd14-5139-5132-8e12-2778fbd54378'

    },
    {
        name: 'Mango Madness',
        image:<StaticImage src = {Mimg} />,
        id:'329a6077-c9cb-505d-b941-39d63cbee7f8'
    },
    {
        name:'Party Pineapple',
        image:<StaticImage src = {Pimg}/>,
        id:'f072d25a-39e6-52b0-99d2-96664069b555'
    }

]